<template>
    <div id="userlogin" class="boxsty login">
    <div class="hello">您好，请使用店铺助手APP(双端)扫码登录</div>
      <div class="pr-code-box">
        <el-empty class="empty-box" :image-size="100" description="功能开发中，敬请期待！"></el-empty>
      </div>
    <el-divider ><span class="line-b">其他登录方式</span></el-divider>
    <div class="ohter-login">
      <div class="img-list">
        <img src="@/assets/icon/pwdlogin.png"  alt="pwd" @click="pwdLogin(1)"/>
        <img src="@/assets/icon/codelogin.png" class="mlr-15" alt="code" @click="codeLogin(2)"/>
        <i class="iconfont icon-weixin" @click="tips"></i>
      </div>
      <div class="register">您还没有注册账号？请立即 <span @click="toreg">免费注册</span></div>
    </div>
  </div>
</template>
<script>
import { eltips } from "@/util/util";
import { resetRouter } from "@/router/index.js";
import store from "@/store/index.js";
export default {
  name: "login",
  data() {
    return {
      phone: "",
      password: "",
      code: "",
      logintype: 1,
      codeshow: false,
      codetime: 59,
      userlogin: "",
      checked: true,
      isRemember: false,
    };
  },
  created() {
    this.userlogin = this.$store.state.userlogin;
    if (localStorage.getItem("username")) {
      this.phone = localStorage.getItem("username");
      this.password = localStorage.getItem("pwd");
    }
  },
  methods: {
    tips(){
      this.$message('功能开发中，敬请期待！')
    },
    //忘记密码
    forgetpwd() {
      this.$store.commit("changeuserlogin", 2);
    },
    //注册
    toreg() {
      this.$emit('handleBackRegister',3,true)
    },
    handlogin(e) {
      this.login();
    },
    //用户名密码登录
    pwdLogin(){
      console.log('----------------')
      this.$emit("handleBackRegister", 1,true,1);
    },
    //验证码登录
    codeLogin(){
      this.$emit("handleBackRegister", 1,true,2);
    },
    //登录
    login() {
      if (this.checked) {
        let data = {
          phone: this.phone,
          type: this.logintype,
          guard_name: "api",
          is_customer: 1,
        };

        if (this.logintype == 1) {
          data.password = this.password;
        } else {
          data.sms_code = this.code;
        }
        if (this.isRemember) {
          localStorage.setItem("username", this.phone);
          localStorage.setItem("pwd", this.password);
        }
        sessionStorage.setItem("guardName", "api");

        this.axios.post("/api/login", data).then((res) => {
          if (res.code == 200) {
            eltips(res.msg, "success");
            sessionStorage.setItem("token", res.data.access_token);
            sessionStorage.removeItem("role_id");
            sessionStorage.removeItem("pluginroute");
            sessionStorage.removeItem("pluginid");
            this.getInfo();
          } else {
            eltips(res.msg, "error");
          }
        });
      } else {
        eltips("请阅读用户使用协议", "warning");
      }
    },
    //获取信息
    getInfo() {
      this.axios
        .get("/api/me", { params: { guard_name: "api" } })
        .then((res) => {
          if (res.code == 200) {
            sessionStorage.setItem("userinfo", JSON.stringify(res.data));
            if (res.data.unitid == 2) {
              sessionStorage.setItem("role_id", res.data.roles[0].id);
            }
            if (res.data.shop_id) {
              this.getmeuns();
            } else {
              let routes = [
                {
                  component: "createStore/createStore.vue",
                  guard_name: "api",
                  icon: "el-icon-s-comment",
                  layout: "0",
                  name: "公告",
                  path: "/createStore",
                  router_name: "createStore",
                  vue_name: "createStore",
                  app_level: 0,
                },
              ];
              //储存路由信息
              let type;
              sessionStorage.removeItem("routestext");
              sessionStorage.removeItem("routes");
              sessionStorage.setItem("routes", JSON.stringify(routes));
              if (res.data.unitid) {
                this.$router.replace({
                  path: "/createStore",
                  query: { unitid: res.data.unitid },
                });
              } else {
                type = 1;
                this.$router.replace({ path: "/createStore" });
              }
            }
          } else {
            eltips(res.msg, "error");
          }
        });
    },
    //获取菜单
    getmeuns() {
      this.axios.get("/api/get_menu").then((res) => {
        if (res.data.length == 0) {
          return;
        }
        resetRouter();
        this.$store.commit("routestatus", 0);
        sessionStorage.removeItem("routestext");
        sessionStorage.removeItem("routes");
        let help = {
          component: "help/helplist.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "指南",
          path: "/help/list",
          router_name: "help",
          vue_name: "helplist",
          app_level: 0,
        };
        let notice = {
          component: "notice/notice.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "公告",
          path: "/notice",
          router_name: "notice",
          vue_name: "notice",
          app_level: 0,
        };
        let noticedetail = {
          component: "notice/noticedetail.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "公告详情",
          path: "/notice/detail",
          router_name: "notice",
          vue_name: "noticedetail",
          app_level: 0,
        };

        let caseroute = {
          component: "case/case.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "案例",
          path: "/case",
          router_name: "case",
          vue_name: "case",
          app_level: 0,
        };

        let casedetail = {
          component: "case/casedetail.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "案例详情",
          path: "/case/detail",
          router_name: "case",
          vue_name: "casedetail",
          app_level: 0,
        };

        let payroute = {
          component: "payapps/payapps.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "付费",
          path: "/payapps",
          router_name: "payapps",
          vue_name: "payapps",
          app_level: 0,
        };

        res.data.splice(1, 0, help);
        res.data.splice(1, 0, notice);
        res.data.splice(1, 0, noticedetail);
        res.data.splice(1, 0, caseroute);
        res.data.splice(1, 0, casedetail);
        res.data.splice(1, 0, payroute);
        console.log("---data", res.data);
        sessionStorage.setItem("routes", JSON.stringify(res.data));

        this.$router.replace({ path: "/index" });
      });
    },
    //切换tab
    changelogin(type) {
      this.logintype = type;
    },
    //获取验证码
    getcode() {
      let that = this;
      this.axios.post("/api/send_code", { phone: this.phone }).then((res) => {
        if (res.code == 200) {
          eltips(res.msg, "success");
          that.codeshow = true;
          let autocode = setInterval(() => {
            if (that.codetime > 1) {
              that.codetime--;
            } else {
              that.codeshow = false;
              that.codetime = 59;
              clearInterval(autocode);
            }
          }, 1000);
        } else {
          eltips(res.msg, "error");
        }
      });
    },
  },
};
</script>

<style>
#userlogin .el-input__inner {
  border: none !important;
  height: 36px !important;
  line-height: 36px !important;
  padding-left: 16px !important;
  border-radius: 0 !important;
  background: transparent;
  box-sizing: border-box;
  border-left: 1px solid #e3e9f3 !important;
}
#userlogin .el-input__inner:focus {
  /* border-color: #3773da !important; */
}
</style>
<style lang="less" scoped>
::v-deep svg{
    position: relative;
    left: 50%;
  }
.login {
  padding: 67px 34px 38px;
  box-sizing: border-box;
  .title {
    text-align: center;
    margin-bottom: 30px;
    .text {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 40px;
      font-size: 20px;
      color: #969799;
      cursor: pointer;
    }
    .active {
      font-size: 26px;
      color: #323233;
    }
  }
  .inputpad {
    padding-top: 30px;
  }
  .inputbox {
    margin-top: 30px;
    position: relative;
    background: #f9fbff;
    border: 1px solid #ecf0f8;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    border-radius: 6px;
    .label {
      font-size: 16px;
      line-height: 47px;
      display: inline-block;
      margin-right: 10px;
    }
    .input {
      font-size: 16px;
      width: 100%;
      height: 48px !important;
      line-height: 48px !important;
      box-sizing: border-box;
    }
    .codebox {
      line-height: 48px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 16px;
      color: #999;
    }
    .inputlabel {
      display: inline-block;
      width: 260px;
      float: right;
      flex: 0 0 260px;
    }
    .input:focus {
      border-color: #3773da;
    }
  }
  .loginbtn {
    height: 60px;
    border-radius: 6px;
    font-weight: 600;
    margin-top: 88px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    background: #479cf7;
    border-radius: 5px;
    &:hover {
      background: #368fee;
      cursor: pointer;
    }
  }
  .logininfo {
    overflow: hidden;
    margin-top: 24px;
    font-size: 14px;
    line-height: 14px;
    .chekbox {
      float: left;
      span {
        color: #155bd4;
      }
    }
    .changbox {
      float: right;
      font-size: 14px;
      .text {
        color: #479cf7;
        padding-right: 10px;
        line-height: 14px;
        display: inline-block;
        &:last-child {
          position: relative;
          padding-right: 0;
          padding-left: 10px;
        }
        &:last-child::after {
          content: "";
          width: 1px;
          height: 14px;
          position: absolute;
          left: 0;
          top: 0;
          background: #ebedf0;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.hello {
  color: #333;
  font-size: 32px;
  line-height: 45px;
  font-weight: 600;
  margin-bottom: 43px;
  text-align: center;
}
.icon-size {
  font-size: 25px;
  color: #abb5c9;
  margin-right: 10px;
}
.remember-password {
  height: 57px;
  font-size: 14px;
  color: #333;
  line-height: 57px;
}
.margin0 {
  margin: 0 auto 50px auto !important;
}
.line-b {
  color: #666;
  font-size: 14px;
}
.changbox {
  float: right;
  font-size: 14px;
  .text {
    color: #155bd4;
    padding-right: 10px;
    line-height: 14px;
    display: inline-block;
    &:last-child {
      position: relative;
      padding-right: 0;
      padding-left: 10px;
    }
    &:last-child::after {
      content: "";
      width: 1px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 0;
      background: #ebedf0;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.ohter-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-list {
    display: flex;
    align-items: center;
    & > i {
      font-size: 50px;
      color: rgb(13, 188, 84);
      cursor: pointer;
    }
    & > img {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
    .mlr-15{
      margin: 0 15px;
    }
  }
  .register {
    color: #333;
    font-size: 14px;
    margin-top: 26px;
    &>span{
      color:#479cf7;
      cursor: pointer;
    }
  }
}
.pr-code-box{
  width: 195px;
  height: 198px;
  margin: 0 auto;

  .empty-box{
    margin: 0 auto;
    text-align: center;
  }
}
</style>
